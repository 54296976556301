.navvBtn {
  width: 144px; /* Ensure proper width */
  height: 45px; /* Increase height for better alignment */
  border-radius: 5px;
  background: rgba(185, 142, 53, 1);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: none;
  color: white;
  padding: 10px 5px;
  line-height: 1; /* Adjust line height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; /* Optional */
  white-space: nowrap; /* Prevents text from breaking into multiple lines */
  overflow: hidden; /* Prevents text overflow */
  text-overflow: ellipsis; /* Adds "..." if text overflows */
}


.navvBtn:hover {
  background: rgba(150, 120, 45, 1);
}
