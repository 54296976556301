.nwcol{
  color:"#d6bd83" !important
}

.carsMainHeading {
  padding-left: 30px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding-top: 360px;
  padding-bottom: 190px;
}

.carsHeading {
  width: 628px;
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.carsText {
  width: 559px;
  padding-top: 5px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

/* Search Css  */

.searchMain {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* z-index: 999; */
  position: relative;
  top: 60px;
}

.searchContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 45px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -140px;
  width: 90%;
  background: rgba(255, 255, 255, 1);
  border-radius: 23px;
  box-shadow: 0px 4px 30.3px 0px rgba(0, 0, 0, 0.09);
}


.inputRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.searchInput {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  background: rgba(244, 246, 249, 1);
  border-radius: 50px;
  font-size: 14px;
  color: rgba(51, 50, 50, 1);

}

.searchBtnContainer {
  display: flex;
  justify-content: center;
}

.searchBtn {
  width: 163px;
  height: 44.35px;
  border-radius: 4.44px;
  background-color: #d6bd83;
  /* background: rgba(185, 142, 53, 1); */
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 15.52px;
  font-weight: 700;
  line-height: 21.14px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.searchBtn:hover {
  background-color: #0056b3;
}


/* Industry css  */

.indusMain {
  padding-top: 40px;
  padding-bottom: 30px;
}

.breContainer {
  background-image: url("../../Images/carBgWebsite.jpg");
  background-position: top center; 
  background-repeat: no-repeat; 
  background-size: contain; 
  width: 100%; 
  height: auto;
  aspect-ratio: 16 / 9;
  cursor: pointer;
}

/* For laptops and desktops */
@media (min-width: 1025px) {
  .breContainer {
    background-image: url("../../Images/carBgLaptop.jpg");
    aspect-ratio: 16 / 9; /* Adjust for the laptop image ratio if different */
  }
}

/* For tablets (e.g., iPads) */
@media (min-width: 768px) and (max-width: 1024px) {
  .breContainer {
    background-image: url("../../Images/carBgI-Pad.jpg");
    aspect-ratio: 4 / 3; /* Adjust for the tablet image ratio */
  }
}

/* For mobile devices (up to 767px) */
@media (max-width: 767px) {
  .breContainer {
    background-image: url("../../Images/carBgPhone.jpg");
    aspect-ratio: 9 / 16; /* Adjust for the phone image ratio */
  }
}

.indusContainer {
  background-image: url("../../Images/indusBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 594px; */
}

.indusHeadingMain {
  padding-top: 280px;
  padding-bottom: 90px;
  padding-left: 60px;
}

.indusHeading {
  width: 501px;
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.indusText {
  padding-top: 5px;
  width: 508px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

/* Feedback css  */

/* .feedbackMain{
    background-color: rgba(210, 205, 205);

} */
.feedbackHeading {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 54.47px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.feedbackSliderMain {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.sliderContainer {
  height: 306.46px;
  width: 431.77px;
  padding: 25.23px;
  border-radius: 4.21px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 16.1px 0px rgba(0, 0, 0, 0.07);
}

.imgRateMain {
  display: flex;
  justify-content: space-between;
}

.SliderHeading {
  padding-top: 10px;
  font-family: Open Sans;
  font-size: 23.13px;
  font-weight: 600;
  line-height: 31.5px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(19, 50, 64, 1);
}

.SliderText {
  padding-top: 10px;
  font-family: Open Sans;
  font-size: 16.82px;
  font-weight: 400;
  line-height: 22.91px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(19, 50, 64, 1);
}

@media screen and (max-width: 948px) {
  .searchBtnMain {
    justify-content: center;
    align-items: center;
  }
  .select1Main {
    align-items: center;
    justify-content: center;
  }
}


@media screen and (max-width: 728px) {
  .searchSelect {
    width: 297px;
  }
  .priceInput1 {
    width: 60px;
  }
  .priceInput2 {
    width: 190px;
  }

  .carsText {
    width: auto;
  }

  .carsHeading {
    font-size: 20px;
    width: auto;
    line-height: 1.5;
  }

  
  .indusHeading {
    width: auto;
    font-size: 20px;
    line-height: 1.5;
  }

  .indusHeadingMain {
    padding: 120px 20px;
  }
}
